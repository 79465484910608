import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  optionProps,
  segmentProps,
} from 'components/molecules/Filter';
import ReportFilter, { getOptions } from 'components/molecules/ReportFilter';
import { titleize } from 'utilities/string';

const coreAssetProps = PropTypes.shape({
  assetType: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  campaign: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  partner: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  uuid: PropTypes.string.isRequired,
});
const propTypes = {
  coreAssets: PropTypes.arrayOf(coreAssetProps).isRequired,
  isOpen: PropTypes.bool.isRequired,
  segments: PropTypes.arrayOf(segmentProps).isRequired,
  selections: PropTypes.objectOf(
    PropTypes.arrayOf(optionProps),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSelectionsChange: PropTypes.func.isRequired,
};

function getCustomOption(asset, key) {
  switch (key) {
    case 'assetType': {
      const value = asset[key];
      const label = titleize(value);
      return [{
        label,
        value,
      }];
    }
    case 'channel':
    case 'market': {
      const idx = key === 'channel' ? 0 : 1;
      return asset.posts.map((combined) => {
        const value = combined.split('::')[idx];
        return {
          label: value,
          value,
        };
      });
    }
    default: {
      const value = asset[key];
      return [{
        label: value,
        value,
      }];
    }
  }
}

function CoreAssetsFilter({
  coreAssets,
  segments,
  selections,
  isOpen,
  onClose,
  onOpen,
  onSelectionsChange,
}) {
  const [options, setOptions] = useState({});

  useEffect(() => {
    setOptions(getOptions(coreAssets, segments, getCustomOption));
  }, [segments, coreAssets]);

  return (
    <ReportFilter
      isOpen={isOpen}
      options={options}
      segments={segments}
      selections={selections}
      onClose={onClose}
      onOpen={onOpen}
      onSelectionsChange={onSelectionsChange}
    />
  );
}

CoreAssetsFilter.propTypes = propTypes;

export default CoreAssetsFilter;
